<template>
  <div class="videoPlay">
    <el-container>
      <el-header height="5vh">
        <div class="zhangjie-btn" @click="clickZhangjie">章节</div>
        <div class="select-div">
          <el-select v-model="value" @change="setPlayRate">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </el-header>
      <el-main>
        <div class="video_box">
          <video ref="myVideo" class="video-js vjs-big-play-centered">
            <source :src="mediaLink" type="video/mp4" />
          </video>
        </div>
      </el-main>
    </el-container>
    <el-drawer :visible.sync="drawer" direction="ltr" :with-header="false">
      <div class="contanier">
        <div class="title">{{ drawerTitle }}</div>
        <div
          class="zhang"
          v-for="(zhangItem, zhangIndex) in courserList"
          :key="zhangItem.id"
        >
          <div class="zhang-name">{{ zhangItem.name }}</div>
          <div
            class="jie-name"
            v-for="(jieItem, jieIndex) in zhangItem.children"
            :key="jieItem.id"
            @click="toVideoPlay(jieItem)"
          >
            {{ zhangIndex + 1 }}-{{ jieIndex + 1 }} {{ jieItem.name }}
            <span v-if="!isMember && !jieItem.onlyForMember" class="free-tip"
              >免费</span
            >
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "videoPlay",
  data() {
    return {
      drawerTitle: "",
      courseId: null,
      id: null,
      contentDetail: null,
      courserList: [],
      mediaLink: "",
      player: null,
      playbackRate: "倍速播放",
      options: [
        {
          value: "0.75",
          label: "0.75倍速",
        },
        {
          value: "1",
          label: "1倍速",
        },
        {
          value: "1.25",
          label: "1.25倍速",
        },
        {
          value: "1.5",
          label: "1.5倍速",
        },
        {
          value: "2",
          label: "2倍速",
        },
      ],
      value: "1",
      drawer: false,
      isMember: false,
    };
  },
  created() {
    this.id = this.$router.history.current.query.id;
    this.courseId = this.$router.history.current.query.courseId;
    this.drawerTitle = this.$router.history.current.query.drawerTitle;
    this.getContentDetail();
    this.getCourserList();
    this.getUserInfo();
  },
  mounted() {},
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  methods: {
    getUserInfo() {
      this.axios
        .get("/loginInfo")
        .then((res) => {
          this.isMember = res.data.isMember;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    clickZhangjie() {
      this.drawer = true;
    },
    setPlayRate(e) {
      this.$refs.myVideo.playbackRate = e;
    },
    getCourserList() {
      this.axios
        .get("/api/common/imageText/anon/courseList", {
          params: {
            courseId: this.courseId,
          },
        })
        .then((res) => {
          this.courserList = res.data;
        });
    },
    toVideoPlay(jieItem) {
      if (!this.isMember && jieItem.onlyForMember) {
        this.$message({
          message: "仅会员可观看",
          type: "warning",
        });
        return;
      }
      this.id = jieItem.id;
      this.getContentDetail();
    },
    getContentDetail() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.axios
        .get("/api/common/imageText/anon/detail", {
          params: {
            id: this.id,
          },
        })
        .then((res) => {
          loading.close();
          this.contentDetail = res.data;
          this.mediaLink = res.data.mediaLink;
          this.initPlayer();
        })
        .catch(() => {
          loading.close();
          this.$router.push("/login");
        });
    },
    initPlayer() {
      this.player = this.$video(this.$refs.myVideo, {
        //确定播放器是否具有用户可以与之交互的控件。没有控件，启动视频播放的唯一方法是使用autoplay属性或通过Player API。
        controls: true,
        //自动播放属性,muted:静音播放
        autoplay: false,
        //建议浏览器是否应在<video>加载元素后立即开始下载视频数据。
        preload: "auto",
        //设置视频播放器的显示宽度（以像素为单位）
        width: 300,
        //设置视频播放器的显示高度（以像素为单位）
        height: 150,
      });
      this.player.off("timeupdate");
      this.player.reset();
      this.player.src({ src: this.mediaLink, type: "video/mp4" });
    },
  },
};
</script>

<style lang="scss" scoped>
.videoPlay {
  .el-header {
    background-color: #000;
    display: flex;
  }
  .el-main {
    background-color: #000;
    color: #333;
    text-align: center;
    line-height: 160px;
    padding: 0;
  }
  .video_box {
    width: 100%;
    height: 95vh;
  }
  .select-div {
    height: 5vh;
  }
  .zhangjie-btn {
    width: 100px;
    height: 5vh;
    background-color: #000;
    color: #fff;
    line-height: 5vh;
    text-align: center;
    cursor: pointer;
    font-size: 15px;
  }
  .contanier {
    .title {
      background-color: #fff;
      text-align: center;
      padding: 20px;
      color: #1c1f21;
      font-size: 16px;
      font-weight: 700;
    }
    .zhang {
      margin-bottom: 8px;
      padding: 24px 32px 15px;
      background: #fff;
      box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.1);
      border-radius: 12px;
      .zhang-name {
        color: #1c1f21;
        font-size: 16px;
        font-weight: 700;
        line-height: 30px;
      }
      .jie-name {
        margin-left: 20px;
        line-height: 30px;
        cursor: pointer;
        &:hover {
          color: #1cb877;
        }
        .free-tip {
          margin-left: 30px;
          background-color: #fffae2;
          color: #f37b1d !important;
          padding: 2px 5px;
        }
      }
    }
  }
}
.video-js {
  width: 100%;
  height: 95vh;
}
::v-deep .el-input__inner {
  background-color: #000;
  border: none;
  border-radius: 0;
  color: #fff;
  width: 100px;
  font-size: 15px;
}
::v-deep .el-drawer.ltr {
  overflow: scroll;
}
</style>